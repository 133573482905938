
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map, finalize} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import * as _ from "lodash";
import * as querystring from "querystring-es3";
import {UrlService} from "../../core/services/url.service";
import {RequestsService} from "./requests.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {LoginCookieService} from "../../core/services/login-cookie.service";
import {UpdateGeneralInfoState, UpdateUserState} from "../../ngrx/app.actions";
import {AppState} from "../../ngrx/app.reducres";
import {Store} from "@ngrx/store";
import {MessageType} from "../../common/model/message-type";
import {Message} from "../../common/model/message.model";
import {NotyService} from "../../common/components/noty/noty.service";
import {LoginConfig} from "../login.config";
import {StatusType} from "../../common/model/status-type";
import {AnalyticsService} from "../../core/services/analytics.service";
import {PlatformModel} from "../../common/model/platform.model";
import {AmpEventSourceTypeDozi} from "../../common/model/amp-event-source-type-dozi";
import {AmpEventSourceTypeZi} from "../../common/model/amp-event-source-type-zi";
import {AmpEventSourceTypeDo} from "../../common/model/amp-event-source-type-do";

@Injectable()
export class ToggleService {

  constructor(private urlService: UrlService,
              private requestsService: RequestsService,
              private router: Router,
              private loginCookieService: LoginCookieService,
              private _store: Store<AppState>,
              private notyService: NotyService,
              private analyticsService: AnalyticsService) { }

  toggleAndRedirect({from, to, fromUrl, toUrl, uuidToken, userIdQp, flow = 'Not Provided', redirectQp = null, forceMovingToAppOnFailure = false}: any) {
    const ziid = this.loginCookieService.getCookie('ziid');
    const zisession = this.loginCookieService.getCookie('zisession');
    const userId = userIdQp || this.loginCookieService.getCookie('userId');
    const parseSessionToken = this.loginCookieService.getCookie('parseSessionToken');
    //
    const body = {
      from,
      to,
      toUrl,
      fromUrl,
      ziid,
      zisession,
      userId,
      parseSessionToken,
      uuidToken
    };
    //
    this.requestsService.toggleUser(body).pipe(
      finalize(() => this._store.dispatch(new UpdateGeneralInfoState({isLoading: false}))),
      map((resp: any) => {
        this.handleToggleUserResp(to, flow, body, resp);
      }),
      catchError((errResp) => {
        const errCode = _.get(errResp, 'error.err.code') || _.get(errResp, 'error.err.resultCode') || 499;
        const errMessage = _.get(errResp, 'error.err.message.errorMessage') || _.get(errResp, 'error.err.message') || _.get(errResp, 'error.err.resultTextCode') || `Wasn't provided`;
        //
        const redirectTo = _.get(errResp, 'error.redirectUrl', undefined);
        // - It's possible that sometimes we won't get the "to" param provided via URL.
        // - For error flow - It's possible that sometimes we will get the "to" field provided via response body from BE flow.
        //   Therefore - we'll always take the "to" from the error response from the server.
        this.sendAnalytics(flow, StatusType.ERROR, body, {errCode, errMessage, from, userId, to: _.get(errResp, 'error.to')});
        //
        throwFromToggleFlow(this, errMessage, errCode, redirectTo);
        return observableOf();
      }),)
      .subscribe();

    /**
     * This method is responsible for throwing the user out of the Toggle Flow with a proper message.
     * **/
    function throwFromToggleFlow(self, message, code, redirectTo) {
      const encodedError = btoa(JSON.stringify({code, message, flow}));
      const baseQp = {toggleFlowError: encodedError};
      //
      // Provided URL to redirect to on failure
      if (redirectTo) {
        //
        const redirectToContainsHash = redirectTo.includes('#');
        const redirectToContainsQueryParams = redirectTo.includes('?');
        const queryParamsPrefix = redirectToContainsQueryParams ? '&' : '?';
        //
        redirectTo = `${redirectTo}${redirectToContainsHash ? '' : '/#/'}${queryParamsPrefix}${querystring.stringify(_.omitBy({...baseQp}, _.isNil))}`;
        window.location.href = redirectTo;
        return;
      }
      // Didn't provided a URL to redirect to on failure
      if (flow === LoginConfig.TOGGLE_FLOWS.LOGIN) {
        if (forceMovingToAppOnFailure) {
          self.router.navigate([''], {queryParams: _.omitBy({...baseQp, autoLogin: true}, _.isNil)});
          return;
        }
        const messageToShow = `Toggle Flow Failed - code: ${code}, message: ${message}`;
        self.notyService.postMessage(new Message(MessageType.ERROR, messageToShow));
        return;
      }
      // Not Login Flow - redirect to login page with error QP
      self.router.navigate([''], {queryParams: _.omitBy({...baseQp, autoLogin: true}, _.isNil)});
    }
  }

  handleToggleUserResp(to, flow, body, resp) {
    this._store.dispatch(new UpdateUserState({redirectUrl: resp.redirectUrl}));
    this.sendAnalytics(flow, StatusType.SUCCESS, body, resp);
    if (resp.ziid) {
      this.loginCookieService.setCookie('ziid', resp['ziid'], 365, '/', environment.cookies_domain);
      this.loginCookieService.setCookie('zisession', resp['zisession'], 365, '/', environment.cookies_domain);
      this.loginCookieService.setCookie('parseSessionToken', resp.parseSessionToken, 365, '/', environment.cookies_domain);
      this.loginCookieService.setCookie('userId', resp.userId, 365, '/', environment.cookies_domain);
      this.loginCookieService.setCookie('userEmail', resp.email, 365, '/', environment.cookies_domain);
      const userCookieName = to === PlatformModel.DOZI ? LoginConfig.ACTIVE_DOZI_USER_COOKIE_NAME : LoginConfig.ACTIVE_ZI_USER_COOKIE_NAME;
      this.loginCookieService.setCookie(userCookieName, resp.email, 90, '/', environment.cookies_domain);
    } else {
      // For toggles from DOZI -> DiscoverOrg there is no more ziid or zisession so delete the cookies
      this.loginCookieService.deleteCookie('ziid', '/', environment.cookies_domain);
      this.loginCookieService.deleteCookie('zisession', '/', environment.cookies_domain);
      this.loginCookieService.deleteCookie('parseSessionToken', '/', environment.cookies_domain);
      this.loginCookieService.deleteCookie('userId', '/', environment.cookies_domain);
      this.loginCookieService.deleteCookie('userEmail', '/', environment.cookies_domain);
    }
    //
    window.location.href = resp.redirectUrl;
  }

  /**
   * This method is responsible for sending the analytics events for the toggle flow
   * **/
  sendAnalytics(flow, status: StatusType, request, response) {
    // Send events only for toggle page
    const flowToSaveAnalytics = [LoginConfig.TOGGLE_FLOWS.TOGGLE_PAGE];
    if (!flowToSaveAnalytics.includes(flow)) {
      return;
    }
    const { to, doziUserId, legacyUserId, errMessage, errCode } = response;
    //
    switch (status) {
      // Analytics event on success
      case StatusType.SUCCESS: {
        let eventName;
        let applicationName;
        if (request.from === PlatformModel.DOZI) {
          eventName = 'TurnedOffDOZIToggle';
          applicationName = this.getApplicationName(to);
        }
        else {
          eventName = 'TurnedOnDOZIToggle';
          applicationName = this.getApplicationName(request.from);
        }
        //
        this.analyticsService.sendAmplitudeEvent(eventName, {source: request.fromUrl, application: applicationName, userId: doziUserId, legacyUserId});
        break;
      }
      // Analytics event on error
      case StatusType.ERROR: {
        const userIdForEvent = request.userId;
        const applicationName = this.getApplicationName(request.from);
        //
        this.analyticsService.sendAmplitudeEvent('ErrorOccurred', {description: `flow: ${flow}, from: ${request.from}, to: ${to}, message: ${errMessage}`, errorCode: errCode, application: applicationName, userId: userIdForEvent});
        break;
      }
    }
  }

  getApplicationName(to: any) {
    if (to === PlatformModel.DOZI) {
      return AmpEventSourceTypeDozi.DOZI;
    }
    if (to === PlatformModel.DO) {
      return AmpEventSourceTypeDo.DO;
    }
    if (to === PlatformModel.ZI) {
      return AmpEventSourceTypeZi.GO;
    }
  }
}
