import * as _ from 'lodash-es';
import * as prodEnv from '../../../environments/environment.prod';
import * as shajs from 'sha.js';

import {ActivatedRoute, Router} from '@angular/router';
import {AppState, getAppState, getUserState} from '../../ngrx/app.reducres';
import { MessageTarget, UserLoggedInData, UserLoggedInMessage } from '@cex/cex-messaging-common';
import {Observable, Subject, of as observableOf, forkJoin} from 'rxjs';
import {UpdateAuthState, UpdateGeneralInfoState} from '../../ngrx/app.actions';
import {mergeMap, take} from 'rxjs/operators';

import {AmpEventSourceTypeCommunity} from "../../common/model/amp-event-source-type-community";
import {AmpEventSourceTypeDozi} from "../../common/model/amp-event-source-type-dozi";
import {AmpEventSourceTypeMarketingCloud} from "../../common/model/amp-event-source-type-marketing-cloud";
import {AmpEventSourceTypeRecruiter} from "../../common/model/amp-event-source-type-recruiter";
import {AmpEventSourceTypeZi} from "../../common/model/amp-event-source-type-zi";
import {AnalyticsService, LoginType} from '../../core/services/analytics.service';
import {CommonService} from "../../common/common.service";
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JwtHelperService} from "@auth0/angular-jwt";
import {LoginConfig} from '../login.config';
import {LoginCookieService} from "../../core/services/login-cookie.service";
import {NotyService} from "../../common/components/noty/noty.service";
import {PlatformModel} from "../../common/model/platform.model";
import {RequestsService} from './requests.service';
import {Store} from '@ngrx/store';
import { StyleNames } from '../../utility/style-names';
import {ToggleService} from "./toggle.service";
import {UrlService} from "../../core/services/url.service";
import {environment} from '../../../environments/environment';
import {AmpEventSourceTypeAdmin} from "../../common/model/amp-event-source-type-admin";

export enum TwoFaScreenType {
  SELECT_AUTH_METHOD,
  OKTA_VERIFY_SELECT_DEVICE,
  OKTA_VERIFY_QR_CODE,
  OKTA_VERIFY_CODE,  // not in set up
  GOOGLE_AUTH_SELECT_DEVICE,
  GOOGLE_AUTH_QR_CODE,
  GOOGLE_AUTH_SETUP_VERIFY_CODE,
  GOOGLE_AUTH_CANT_SCAN,
  GOOGLE_AUTH_VERIFY_CODE,
  EMAIL_AUTH_SETUP_VERIFY_CODE,
  EMAIL_AUTH_VERIFY_CODE,
  SMS_AUTH_SETUP,
  SMS_AUTH_VERIFY_CODE
}

@Injectable()
export class LoginService {

  constructor(private _router: Router, private _activatedRoute: ActivatedRoute,
              private analyticsService: AnalyticsService, private _http: HttpClient, private _store: Store<AppState>,
              private requestsService: RequestsService, private urlService: UrlService,
              private loginCookieService: LoginCookieService, private notyService: NotyService,
              private toggleService: ToggleService, private commonService: CommonService) { }
  // Observable string sources
  emitChangeSourceForNoty = new Subject<any>();
  // Observable string streams
  changeEmittedForNoty$ = this.emitChangeSourceForNoty.asObservable();

  isSsoAutoSubmit = false;
  isSocialLoginInProgress = false;
  usernameForSso: string;

  private isAppLauncherRecAdmin = false;

  /** Check if the url is from Skilljar, and if so add the query parameters to redirect url. */
  static handleSkilljarRedirectUrl(url: string, redirectUrl: string): string {
    const params = UrlService.getSkilljarUrlParams(url);
    if (params) {
      const redirectSearchParams = new URLSearchParams(params);
      redirectUrl += `?${redirectSearchParams.toString()}`;
    }
    return redirectUrl;
  }

  /*
  ** Validate password
  ** Per Figma, password must be 8-20 characters with- letters, numbers, and special characters.
  */
  static isValidPasswordFormat(value: string): boolean {
    const lowerCaseLetters = value.match(/([a-z])/g);
    const numbers = value.match(/\d/g);
    const specialChars = value.match(/\W/g);
    if (value.length >= 8 && value.length <= 20) {
      if (lowerCaseLetters && numbers && specialChars) {
        return true;
      }
    }
    return false;
  }

  // get redirect url with the multi-platforms info from RO SSO login popup url and store to local storage
  // popup url format: {loginPage}/sso-login-popup-check-cookie-close-window?redirectUrl={loginPage}?isReachout=true&loginAsMultiPlatforms={value}
  static handleRoSsoPopupRedirectUrl(url: string) {
    const urlValues = url.split('?');
    if (urlValues.length === 3) {
      const urlParams = new URLSearchParams(urlValues[2]);
      const appDomain = new URLSearchParams(urlValues[1]).get('redirectUrl');
      const isReachout = urlParams.get('isReachout') === 'true';
      const loginAsMultiPlatforms = urlParams.get('loginAsMultiPlatforms');
      if (appDomain && isReachout && loginAsMultiPlatforms) {
        const roSsoPopupRedirectUrl = `${appDomain}?${urlValues[2]}`;
        console.log(`roSsoPopupRedirectUrl=${roSsoPopupRedirectUrl}`);
        localStorage.setItem('roSsoPopupRedirectUrl', roSsoPopupRedirectUrl);
        if (window.opener) {
          window.opener.postMessage({roSsoPopupRedirectUrl: roSsoPopupRedirectUrl}, location.origin);
        }
      }
    }
  }

  // check if environment is production
  static isProdEnv() {
    return environment.app_domain === prodEnv.environment.app_domain;
  }

  static isAuthorizedSamlSsoRedirectUrl(redirectUrl: string) {
    return redirectUrl.startsWith(environment.oktaLoginUrl) &&
      (redirectUrl.includes('skilljar') || redirectUrl.includes('salesforce'));
  }

  static getOktaTokenStorage() {
    return JSON.parse(localStorage.getItem('okta-token-storage'));
  }

  // there will be code and state query params after successful Okta widget login
  static isSuccessfulLoginQp(paramStr: string): boolean {
    return paramStr.includes('code=') && paramStr.includes('state=');
  }

  // check if login page is in iframe
  static isInIframe(): boolean {
    return window.self !== window.top;
  }

  // check if login page is in webview
  static isInWebView(): boolean {
    const pathname = window.location.pathname;
    return pathname.includes('webview') || localStorage.getItem('isInWebview') === 'true';
  }

  // if in webview, delete isInWebview local storage and send message to mobile app with the necessary query params
  static handleWebviewFlow(ziid: string, zisession: string, ziaccesstoken: string, loginType: LoginType) {
    if (LoginService.isInWebView()) {
      localStorage.removeItem('isInWebview');
      LoginService.reactNativeWebViewPostMessage({
        ziid,
        zisession,
        ziaccesstoken,
        loginType
      });
    }
  }

  // postMessage to mobile app
  static reactNativeWebViewPostMessage(message: any) {
    try {
      // this postMessage accepts a string only
      window["ReactNativeWebView"].postMessage(JSON.stringify(message));
    } catch (err) {
      console.error(err);
    }
  }

  // retrieve username from mobile app
  getUsernameFromMobile(): string {
    return this.loginCookieService.getCookie('username');
  }

  /** Check if the mobile SSO login was initiated. */
  isMobileAutoSsoLoginInitiated(): boolean {
    return localStorage.getItem('mobileAutoSsoLoginInitiated') === 'true';
  }

  // check if user have both SalesOS and AdminPortal
  static isSosAdmin(availablePlatformsNames: Array<string>): boolean {
    const lowerCasePlatforms = availablePlatformsNames.map(platform => platform.toLowerCase());
    return lowerCasePlatforms.includes('dozi') && lowerCasePlatforms.includes('admin');
  }

  // get the CSS class names for the background based on if the user is a copilot user or not
  static getBackgroundInfo(isCopilotUser: boolean) {
    const backgroundCssClass = isCopilotUser ? StyleNames.BACKGROUND_CSS_CLASS_COPILOT : StyleNames.BACKGROUND_CSS_CLASS_DEFAULT;
    const unionBgCssClass = isCopilotUser ? StyleNames.BACKGROUND_UNION_CSS_CLASS_COPILOT : StyleNames.BACKGROUND_UNION_CSS_CLASS_DEFAULT;
    return { backgroundCssClass, unionBgCssClass };
  }

  static getDecodedZiAccessToken(ziaccesstoken: string): any {
    try {
      return new JwtHelperService().decodeToken(ziaccesstoken);
    } catch (error) {
      console.error('Error decoding ziaccesstoken', error);
      return {};
    }
  }

  /**
   * @parseErrorFromServer
   *
   * This method is responsible for parsing the error from the server and response with a proper object.
   * **/
  parseErrorFromServer(err, defaultErrorMessage) {
    const defaultErrorObj = {message: {errorMessage: defaultErrorMessage}, code: err.status || 500};
    const errorObj = _.get(err, 'error', defaultErrorObj);
    const finalError = Object.keys(errorObj).length ? errorObj : defaultErrorObj;

    return finalError;
  }

  /**
   * @parseBeResponse
   *
   * This method is responsible for parsing the new response from the new Login BE.
   * @output { data, anura }
   * **/
  parseBeResponse(resp) {
    const { data, anura } = resp;
    return {data, anura};
  }

  sendLoginAmpEvent(used2FA) {
    let appState;
    this._store.select(getAppState).pipe(take(1)).subscribe((state) => appState = state);
    const { user } = appState;
    const platform = this.getCurrentPlatform();
    const zoomRoles = _.get(user, 'zoomRoles', []);
    const machineIdForAnalytics =  this.loginCookieService.getCookie('machineId') || "null";

    const generalUserData = {
      Admin: _.get(user, 'isAdmin', false),
      CompanyID: _.get(user, 'zoomCompanyId', 0),
      RemainingUserCredits: _.get(user, 'credits', -1),
      MachineID: machineIdForAnalytics,
      Used2FA: used2FA
    };

    const redirectUrl = this.urlService.getLoginFinalRedirectUrl();
    const infoFromRedirectUrl = this.urlService.getRedirectUrlInfo(redirectUrl);
    const ampEventLoginTo = this.getAmpEventLoginTo(platform, infoFromRedirectUrl, zoomRoles);
    //
    const ampEventProperties = Object.assign({}, LoginConfig.INITIAL_LOGGED_IN_EVENT_PARAMS, {LoginTo: ampEventLoginTo}, generalUserData);

    this.analyticsService.sendAmplitudeEvent('LoggedIn', ampEventProperties);
  }

  /**
   *
   * **/
  commonActionsAfterLoginRelatedApiFailure(err) {
    if (_.get(err, 'error.blockScript') && _.get(err, 'error.appId')) {
      return this.pixelBlockScriptHandler(err.error);
    }
  }

  /**
   * @setCookiesAndRedirectAfterLogin
   *
   * This method is responsible for:
   *    1. Setting up the routing Cookie
   *    2. Setting up the session cookies
   *    3. Redirect to the relevant url by the following priority:
   *      A. University page, let's redirect him to University.
   *      B. "redirect" Query Param in the URL, let's redirect him to that.
   *      C. Redirect url provided from the backend.
   * **/
  setCookiesAndRedirectAfterLogin(resp) {
    this.routingCookieHandling();

    // Getting App State
    this._store.select(getAppState).pipe(
      take(1))
      .subscribe((appState) => {
        const userState = _.get(appState, 'user');
        const authState = _.get(appState, 'auth');
        const generalInfoState = _.get(appState, 'generalInfo');

        this.setLoginCookies(resp, userState);

        // Get Final Redirect URL
        const finalRedirectUrl = this.urlService.getLoginFinalRedirectUrl();
        const infoFromRedirectUrl = this.urlService.getRedirectUrlInfo(finalRedirectUrl);

        // University Login
        const universityLoginToken = authState.universityLoginToken;
        if (universityLoginToken) {
          return this.buildUniversityLoginAndRedirect(universityLoginToken);
        }

        /**
         * Check if it's a contributor.
         *    A. If it is - connect him to platform and redirect with relevant URL params
         *    B. If not - perform a regular redirection
         * **/
        if (generalInfoState.isContributor) {
          return this.contributorConnectAndLogin(generalInfoState, finalRedirectUrl, userState, authState);
        }
        /**
         * Toggle Flow
         *
         * Restrict Extensions
         *
         * **/
        if (userState.toggle) {
          return this.handleToggledUserAfterSuccessfulLogin(userState);
        }
        // Regular redirect
        window.location.href = finalRedirectUrl;
      });
  }

  /**
   * This method's purpose is to keep the current details we have on URL:
   *    1. Query Params
   *    2. Root Route Path
   * **/
  navigateWithUrlInfo(route) {
    const queryParams = this._activatedRoute.snapshot.queryParams;
    let pathFromRoot = this._router.url.split('?')[0];
    pathFromRoot = pathFromRoot.replace('/logout', '/');
    const finalRoute = pathFromRoot + route;
    this._router.navigate([finalRoute], {queryParams});
  }

  /**
   * This method responds with the machineId for 2FA.
   *
   * Considering 2 cases:
   *    A. In case there is already machineId in the cookies --> return that.
   *    B. In case there isn't --> Calculate, save in the cookies and return that.
   *
   * @returns machineId
   * **/
  twoFaMachineIdHandling(isOktaLogin = false) {
    const machineIdCookieName = isOktaLogin ? LoginConfig.MACHINE_ID_STRING_CONVENTION_OKTA : LoginConfig.MACHINE_ID_STRING_CONVENTION;
    if (!this.loginCookieService.getCookie(machineIdCookieName)) {
      const machineId = this.calcMachineId();
      this.loginCookieService.setCookie(machineIdCookieName, machineId, 365, '/', environment.cookies_domain);
      return machineId;
    }
    return this.loginCookieService.getCookie(machineIdCookieName);
  }

  /**
   * @is_support_err
   *
   * This method is responsible to identify weather the error we got from the backend
   * Is a support error message or not.
   * **/
  isSupportErr(err) {
    const errToCheck = _.get(err, 'errorMessage', '');
    if (errToCheck) {
      return errToCheck.indexOf('contact support') > -1;
    }
    return false;
  }

  /**
   * Extract the relevant home page of the user by providing the response from User Details endpoint
   * **/
  extractHomePageByUserDetailsResp(userDetailsResp) {
    // If there is a "redirect" query param provided via URL and it's valid --> redirect to it
    const redirectQp = this.urlService.getInfoFromUrl().redirectQp;
    if (redirectQp && this.urlService.isUrlAllowedForRedirect(redirectQp)) {
      return redirectQp;
    }

    let homePage = _.get(userDetailsResp, 'appUrls.webAppUrl');
    /**
     * Modify Home Page
     * **/
    if (this.urlService.isUrlAllowedForRedirect(homePage)) {
      /**
       * subscriber
       * **/
      if (homePage.includes('subscriber')) {

        // If "ziversion" cookie === '2.0' --> redirect to Enterprise
        if (this.loginCookieService.getCookie('ziversion') === '2.0') {
          console.log(`user, redirect to :: '${environment.zi_url_enterprise}' for ziid :: '${this.loginCookieService.getCookie('ziid')}' :: page :: '${homePage}'`);
          return environment.zi_url_enterprise;
        }
        // Replacing http to https for subscriber domains ONLY!
        homePage = homePage.replace('http://', 'https://');
      }
      /**
       * Dev / Staging Cases
       * **/
      if (!environment.production) {
        // Change only Grow Domains and not 1.0 domains to Grow Staging URL
        homePage = homePage.replace('ce.zoominfo', 'grow-staging.zoominfo').replace('go.zoominfo', 'grow-staging.zoominfo');

        // Change only DOZI Domain to DOZI Staging Domain
        homePage = homePage.replace('app.zoominfo', 'dozi-staging.zoominfo');

        // Change only 1.0 Domain and not Grow domains to Subscriber Staging URL
        homePage = homePage.replace('subscriber.zoominfo.com', 'subscriber.cloud-2.ge-staging.zoominfo.com/nextgen');
      }
      return homePage;
    }
    return environment.website_domain;
  }

  /**
   * This part is temporary for controlling the traffic on app engine for the release of ng5 from ce to go
   * **/
  routingCookieHandling(expirationDate?) {
    expirationDate = expirationDate || LoginConfig.DEFAULT_EXPIRATION_DATE_FOR_ROUTING_COOKIE;
    let routingCookie = this.loginCookieService.getCookie('GOOGAPPUID');
    if (!routingCookie) {
      routingCookie = (1 + Math.floor(Math.random() * 998)).toString();
      this.loginCookieService.setCookie('GOOGAPPUID', routingCookie, expirationDate, '/', environment.cookies_domain);
    }
  }

  /**
   * This method is responsible to build the university login endpoint and redirect to that.
   * It's purpose is to do all the relevant logic in one place.
   * **/
  buildUniversityLoginAndRedirect(universityLoginToken) {
    const nextQp = this._activatedRoute.snapshot.queryParams.next || '/';
    const finalRedirectUrl = `${LoginConfig.ZOOM_UNIVERSITY_DOMAIN}${LoginConfig.SKILLJAR_LOGIN_ENDPOINT}?token=${universityLoginToken}&next=${encodeURIComponent(nextQp)}`;
    window.location.href = finalRedirectUrl;
  }

  /**
   * This method is responsible for posting messages to RO
   * Using try{} catch(){} for safety
   * **/
  postMessageToReachoutApp(message) {
    try {
      // Post a message to "ReachOut" Chrome Extension's window
      window.parent.postMessage({name: message, target: 'EXTENSION'}, '*');
      console.log(`Posted a message to RO :: ${message}`);
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Send messages to RO after successful login
   */
  loginToReachOutAndSendToPlatform(platform) {
    this.postmessageToReachoutAppWithUserAuthData();
    this.postMessageToReachoutAppWithAppMode(platform);
  }

  /**
   * This method is responsible for posting messages to RO with the correct mode
   * **/
  private postMessageToReachoutAppWithAppMode(anura) {
    const extensionMode = this.getExtensionMode(anura);
    if (!environment.production) {
      console.log(`posting message to reachout app: ${extensionMode}`);
    }
    window.parent.postMessage({ name: 'setAppMode', target: 'IFRAME', mode: extensionMode }, '*');
  }

  /**
   * This method is responsible for posting message to RO with user's auth data to support RO's navbar content and feature flagging
   */
  private postmessageToReachoutAppWithUserAuthData(): void {
    if (!environment.production) {
      console.log(`posting UserLoggedIn to reachout app`);
    }

    const ziId = this.loginCookieService.getCookie('ziid');
    const ziSession = this.loginCookieService.getCookie('zisession');
    const ziAccessToken = this.loginCookieService.getCookie('ziaccesstoken');
    const zoomUserId = this.loginCookieService.getCookie('userId');
    const isCopilotUser = this.loginCookieService.getCookie(LoginConfig.COPILOT_USER_COOKIE_NAME) === 'true';
    const authData: UserLoggedInData = { zoomUserId, ziId, ziSession, ziAccessToken, isCopilotUser };
    window.parent.postMessage({ name: UserLoggedInMessage.MESSAGE_TYPE, target: MessageTarget.REACHOUT_EXTENSION_APP, data: authData }, '*');  // NOSONAR
  }

  /**
   * this function returns the extension mode by the platform - currently supports only recruiter and DOZI
   * **/
  getExtensionMode(anura) {
    switch (anura) {
      case PlatformModel.RECRUITER: return 'RECRUITER';
      case PlatformModel.DOZI: default: return 'DOZI';
    }
  }

  /**
   * this function returns reachout url by the relevant anura
   * **/
  getReachoutUrlByAnura(anura) {
    switch (anura) {
      case PlatformModel.RECRUITER: return environment.recruiter_url_reachout;
      case PlatformModel.DOZI: default: return environment.dozi_url_reachout;
    }
  }

  /**
   * this function returns relevant anura by the reachout url
   * **/
  getAnuraByReachoutUrl(url: string) {
    return url === environment.recruiter_url_reachout ? PlatformModel.RECRUITER : PlatformModel.DOZI;
  }

  /**
   * This method is responsible for posting messages to SF app
   * Using try{} catch(){} for safety
   * **/
  postMessageToSFApp(options) {
    try {
      const isLziSfna = this.isLziSfna();
      //
      if (isLziSfna) {
        const sfPostObject = {
          'x-ziid': options.ziid,
          'x-zisession': options.zisession,
          'user': options.userId,
          'session-token': options.parseSessionToken,
          'zoom_role': options.zoom_role,
          'name': options.name,
          'email': options.email,
          'target': 'SFDC'
        };
        // Post a message to "SalesForce" Chrome Extension's window
        window.parent.postMessage(sfPostObject, '*');
        console.log(`Posted a message to SF :: ${JSON.stringify(sfPostObject)}`);
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * This method is responsible for the base logic of getting the current platform the user belongs to
   * first checks if the url is the lzi login url, and if not:
   * @Rules:
   *  1. Exists on state --> That's the last indication we made on the state so !! Take from State !!
   *
   *  2. ZI:
   *    isRedirectedFromZi || isContributor || isUniversityPage
   *
   *  3. DOZI:
   *    isRedirectedFromDozi
   * **/
  getCurrentPlatform(): string {
    let appState;
    const infoFromUrl = this.urlService.getInfoFromUrl();
    this._store.select(getAppState).subscribe((state) => appState = state);
    const { generalInfo } = appState;
    const { isContributor, isUniversityPage, platform } = generalInfo;
    const { isRedirectedFromZi, isRedirectedFromDozi, isRedirectedFromRecruiter, isRedirectedFromMarketingCloud, isRedirectedFromZIBackOffice, isRedirectedFromAdmin, isRedirectedFromDoziLite } = infoFromUrl;
    const isLziLoginVersion = this.commonService.isLziLoginVersion();
    //
    if (isLziLoginVersion) { // if it's lzi login page - we want the platform to be always zi
      return PlatformModel.ZI;
    }
    if (platform) { return platform; }
    if (isRedirectedFromZi || isContributor || isUniversityPage || isRedirectedFromZIBackOffice) { return PlatformModel.ZI; }
    if (isRedirectedFromMarketingCloud) { return PlatformModel.MARKETING_CLOUD; }
    if (isRedirectedFromDozi) { return PlatformModel.DOZI; }
    if (isRedirectedFromRecruiter) { return PlatformModel.RECRUITER; }
    if (isRedirectedFromAdmin) { return PlatformModel.ADMIN; }
    if (isRedirectedFromDoziLite) { return PlatformModel.ZI_LITE; }
    // Respond with empty string by default
    return '';
  }

  /**
   * Private Methods
   * **/
  private calcMachineId() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  /**
   * Set the relevant cookies after successful login
   * **/
  setLoginCookies(resp, userState?) {
    this.loginCookieService.setCookie('ziid', resp['ziid'], 7, '/', environment.cookies_domain);
    this.loginCookieService.setCookie('zisession', resp['zisession'], 7, '/', environment.cookies_domain);
    if (resp['ziaccesstoken']) {
      this.loginCookieService.setCookie('ziaccesstoken', resp['ziaccesstoken'], 7, '/', environment.cookies_domain);
      // Set cookie for cache busting
      const helper = new JwtHelperService();
      const decodedAccessToken = helper.decodeToken(resp['ziaccesstoken']);
      this.loginCookieService.setCookie(LoginConfig.LATEST_TOKEN_EXPIRATION_COOKIE_NAME, String(decodedAccessToken.exp), 7, '/', environment.cookies_domain);

      const APP_IFRAME_CONTAINER = _.get(document.getElementById("app_iframe_container"), 'contentWindow');
      if (APP_IFRAME_CONTAINER) {
        APP_IFRAME_CONTAINER.postMessage({ key: 'ziaccesstoken', value: resp['ziaccesstoken'], target: "LOGIN_MESSAGE", action: "Set" }, environment.dozi_url_enterprise + LoginConfig.SESSION_STORAGE_POST_URL);
        APP_IFRAME_CONTAINER.postMessage({ key: LoginConfig.LATEST_TOKEN_EXPIRATION_COOKIE_NAME, value: decodedAccessToken.exp, target: "LOGIN_MESSAGE", action: "Set" }, environment.dozi_url_enterprise + LoginConfig.SESSION_STORAGE_POST_URL);
      }
    }
    if (resp['autossologin']) {
      this.loginCookieService.setCookie('autossologin', resp['autossologin'], 365 * 2, '/', environment.cookies_domain); // 2 years
      const APP_IFRAME_CONTAINER = _.get(document.getElementById("app_iframe_container"), 'contentWindow');
      if (APP_IFRAME_CONTAINER) {
        APP_IFRAME_CONTAINER.postMessage({ key: 'autossologin', value: resp['autossologin'], target: "LOGIN_MESSAGE", action: "Set" }, environment.dozi_url_enterprise + LoginConfig.SESSION_STORAGE_POST_URL);
      }
    }
    const redirectUrl = this.urlService.getLoginFinalRedirectUrl();
    const infoFromRedirectUrl = this.urlService.getRedirectUrlInfo(redirectUrl);
    console.log(infoFromRedirectUrl.isRedirectedFromSFNA);
    if (resp['ssoredirecturl']) {
      this.loginCookieService.setCookie('ssoredirecturl', decodeURIComponent(resp['ssoredirecturl']), new Date(new Date().getTime() +  3 * 60 * 1000), '/', environment.cookies_domain);
      const APP_IFRAME_CONTAINER = _.get(document.getElementById("app_iframe_container"), 'contentWindow');
      if (APP_IFRAME_CONTAINER) {
        APP_IFRAME_CONTAINER.postMessage({ key: 'ssoredirecturl', value: resp['ssoredirecturl'], target: "LOGIN_MESSAGE", action: "Set" }, environment.dozi_url_enterprise + LoginConfig.SESSION_STORAGE_POST_URL);
      }
    }
    this.loginCookieService.setCookie('parseSessionToken', resp.parseSessionToken, 365, '/', environment.cookies_domain);
    this.loginCookieService.setCookie('userId', resp.userId, 90, '/', environment.cookies_domain);
    this.loginCookieService.setCookie('userEmail', resp.email, 90, '/', environment.cookies_domain);
    this.loginCookieService.setCookie('email', resp.email, 90, '/', environment.cookies_domain);
    this.loginCookieService.setCookie('name', resp.name, 90, '/', environment.cookies_domain);
    this.loginCookieService.setCookie('firstname', resp.firstname, 90, '/', environment.cookies_domain);
    this.loginCookieService.setCookie('userZoomCompanyId', resp.zoomCompanyId || userState?.zoomCompanyId, 90, '/', environment.cookies_domain);
    //
    this.loginCookieService.setCookie('analyticsId', resp.userId, 90, '/', environment.cookies_domain);

    // Set session storage
    const tokensInSessionStorage = [{'key': 'ziid', 'value': resp['ziid']}, {'key': 'zisession', 'value': resp['zisession']},
      {'key': 'parseSessionToken', 'value': resp.parseSessionToken}, {'key': 'userId', 'value': resp.userId},
      {'key': 'userEmail', 'value': resp.email}, {'key': 'email', 'value': resp.email}, {'key': 'name', 'value': resp.name},
      {'key': 'firstname', 'value': resp.firstname}, {'key': 'userZoomCompanyId', 'value': resp.zoomCompanyId}, {'key': 'analyticsId', 'value': resp.userId}];

    const APP_IFRAME_CONTAINER = _.get(document.getElementById("app_iframe_container"), 'contentWindow');
    tokensInSessionStorage.forEach(tokenItem => {
      if (APP_IFRAME_CONTAINER) {
        APP_IFRAME_CONTAINER.postMessage({ key: tokenItem.key, value: tokenItem.value, target: "LOGIN_MESSAGE", action: "Set" }, environment.dozi_url_enterprise + LoginConfig.SESSION_STORAGE_POST_URL);
      }
    });
  }

  /**
   * Clear the login cookies
   *
   * 1. ziid
   * 2. zisession
   * 3. parseSessionToken
   * 4. userId
   * 5. analyticsId
   * **/
  clearLoginCookies() {
    this.loginCookieService.deleteCookie('ziid', '/', environment.cookies_domain);
    this.loginCookieService.deleteCookie('zisession', '/', environment.cookies_domain);
    this.loginCookieService.deleteCookie('ziaccesstoken', '/', environment.cookies_domain);
    this.loginCookieService.deleteCookie('parseSessionToken', '/', environment.cookies_domain);
    this.loginCookieService.deleteCookie('userId', '/', environment.cookies_domain);
    this.loginCookieService.deleteCookie('userEmail', '/', environment.cookies_domain);
    this.loginCookieService.deleteCookie('email', '/', environment.cookies_domain);
    this.loginCookieService.deleteCookie('name', '/', environment.cookies_domain);
    this.loginCookieService.deleteCookie('firstname', '/', environment.cookies_domain);
    this.loginCookieService.deleteCookie(LoginConfig.LATEST_TOKEN_EXPIRATION_COOKIE_NAME, '/', environment.cookies_domain);
    this.loginCookieService.deleteCookie(LoginConfig.LOOP_COOKIE_NAME, '/', environment.cookies_domain);
    // The old login set that cookie too, TODO - need to ask if this cookie is needed:
    this.loginCookieService.deleteCookie('analyticsId', '/', environment.cookies_domain);
    const tokenNames = ['ziid', 'zisession', 'ziaccesstoken', 'parseSessionToken', 'userId', 'userEmail', 'email', 'name', 'firstname', LoginConfig.LOOP_COOKIE_NAME, 'analyticsId', LoginConfig.LATEST_TOKEN_EXPIRATION_COOKIE_NAME];
    const APP_IFRAME_CONTAINER = _.get(document.getElementById("app_iframe_container"), 'contentWindow');
    tokenNames.forEach(tokenName => {
      sessionStorage.removeItem(tokenName);
      if (APP_IFRAME_CONTAINER) {
        APP_IFRAME_CONTAINER.postMessage({ key: tokenName, target: "LOGIN_MESSAGE", action: "Delete" }, environment.dozi_url_enterprise + LoginConfig.SESSION_STORAGE_POST_URL);
      }
    });
  }

  clearLoopCookie() {
    this.loginCookieService.deleteCookie(LoginConfig.LOOP_COOKIE_NAME, '/', environment.cookies_domain);
  }

  /**
   * set the loop cookies
   * @param clearLoginCookies
   */
  setLoopCookie(loginLoopCookie) {

    // create cookie with ttl of 45 seconds
    const loginLoopCounter = loginLoopCookie ? parseInt(loginLoopCookie) + 1 : 1;
    const loopDetectorCookieExpired = new Date(new Date().getTime() + (LoginConfig.LOOP_COOKIE_EXPIRATION));

    // set the cookie
    this.loginCookieService.setCookie(
      LoginConfig.LOOP_COOKIE_NAME,
      loginLoopCounter.toString(),
      loopDetectorCookieExpired
    );
    const APP_IFRAME_CONTAINER = _.get(document.getElementById("app_iframe_container"), 'contentWindow');
    if (APP_IFRAME_CONTAINER) {
      APP_IFRAME_CONTAINER.postMessage({ key: LoginConfig.LOOP_COOKIE_NAME, value: loginLoopCounter.toString(), target: "LOGIN_MESSAGE", action: "Set" }, environment.dozi_url_enterprise + LoginConfig.SESSION_STORAGE_POST_URL);
    }
  }

  /**
   * This method is responsible for connecting to one of the contributor platforms:
   *    1. ICE
   *    2. Swapper
   * **/
  /* istanbul ignore next */
  private contributorConnectAndLogin(generalInfoState, finalRedirectUrl, userState, authState) {
    const token = _.get(generalInfoState, 'parsedContributorParam.token');
    const clientId = _.get(generalInfoState, 'parsedContributorParam.client_id');
    const platform = _.get(generalInfoState, 'platform');
    const ziid = this.loginCookieService.getCookie('ziid');
    const zisession = this.loginCookieService.getCookie('zisession');

    const userId = _.get(userState, '_id');
    const parseSessionToken = _.get(authState, 'parseSessionToken');
    const name = _.get(userState, 'name');

    return observableOf(null).pipe(
      take(1),
      mergeMap(() => {
        if (userId) {
          if (token) { return this.requestsService.connectUserWithToken(ziid, zisession, userId, parseSessionToken, token, platform); }
          if (clientId) { return this.requestsService.connectUserWithClientId(ziid, zisession, userId, parseSessionToken, clientId, platform); }
        }
        return observableOf(null);
      }),)
      .subscribe(
        (resp) => {
          const {anura, data} = this.parseBeResponse(resp);
          if (!_.get(data, 'credits')) {
            return window.location.href = finalRedirectUrl;
          }
          const paramsObj = {credits: data['credits'], name};
          let popUpParamsObj = JSON.stringify(Object.assign({}, generalInfoState.parsedContributorParam, paramsObj));
          popUpParamsObj = btoa(popUpParamsObj);
          window.location.href = finalRedirectUrl + LoginConfig.GROW_PROSPECTING_PAGE + popUpParamsObj;
        },
        (err) => {
          window.location.href = finalRedirectUrl;
        }
      );
  }

  /**
   * This method is responsible for the PIXEL block script handling
   *
   * !! Set the scripts under the app_domain path (login.zoominfo.com / login-staging.zoominfo.com)
   * For times i run the app locally infront of Staging / Prod !!
   *
   * **/
  private pixelBlockScriptHandler(err) {
    window['_pxAppId'] = err.appId;
    window['_pxJsClientSrc'] = environment.app_domain + err.jsClientSrc;
    window['_pxFirstPartyEnabled'] = err.firstPartyEnabled;
    window['_pxVid'] = err.vid;
    window['_pxUuid'] = err.uuid;
    window['_pxHostUrl'] = environment.app_domain + err.hostUrl;
    window['_pxreCaptchaTheme'] = 'light';
    window['_pxOnCaptchaSuccess'] = () => {
      console.log('_pxOnCaptchaSuccess got triggered !!!');
      this._store.dispatch(new UpdateGeneralInfoState({pixelCaptchaNeeded: false, pixelCaptchaRefId: '', pixelInitScript: ''}));
    };
    // Updating state with pixel data
    this._store.dispatch(new UpdateGeneralInfoState({
      pixelCaptchaNeeded: true,
      pixelInitScript: environment.app_domain + err.blockScript,
      pixelCaptchaRefId: this.getRefId()
    }));
  }

  /**
   * This method is responsible to extract the Reference ID for the PX Captcha
   * **/
  private getRefId() {
    let pxUuid = window['_pxUuid'];
    //
    if (!pxUuid) {
      const url = window.location.href;
      const regex = new RegExp('[?&]uuid(=([^&#]*)|&|#|$)');
      const results = regex.exec(url);
      pxUuid = _.get(results, 'length') > 2 ? results[2] : '';
      pxUuid = decodeURIComponent(pxUuid.replace(/\+/g, ' '));
    }
    //
    return pxUuid;
  }

  /**
   * This method is responsible to provide the relevant Amplitude event source
   * **/
  /* istanbul ignore next */
  private getAmpEventLoginTo(platform, infoFromRedirectUrl, zoomRoles) {
    //
    switch (platform) {
      case PlatformModel.DOZI: {
        if (infoFromRedirectUrl.isRedirectedFromReachOut) {
          return AmpEventSourceTypeDozi.REACHOUT;
        }
        else if (infoFromRedirectUrl.isRedirectedFromSFNA) {
          return AmpEventSourceTypeDozi.SFNA;
        }
        else if (infoFromRedirectUrl.isRedirectedFromMDNA) {
          return AmpEventSourceTypeDozi.MDNA;
        }
        else {
          return AmpEventSourceTypeDozi.DOZI;
        }
      }
      case PlatformModel.ZI_LITE:
        return AmpEventSourceTypeDozi.ZI_LITE;
      case PlatformModel.ZI: {
        if (infoFromRedirectUrl.isRedirectedFromReachOut) {
          return this.getReachOutEnterprise(zoomRoles);
        }
        else if (infoFromRedirectUrl.isRedirectedFromZi) {
          return this.getZIEnterprise(zoomRoles);
        }
        else {
          return AmpEventSourceTypeZi.OTHER;
        }
      }
      case PlatformModel.RECRUITER: {
        if (infoFromRedirectUrl.isRedirectedFromReachOut) {
          return AmpEventSourceTypeRecruiter.REACHOUT;
        }
        return AmpEventSourceTypeRecruiter.RECRUITER;
      }
      case PlatformModel.MARKETING_CLOUD: {
        return AmpEventSourceTypeMarketingCloud.MARKETING;
      }
      case PlatformModel.COMMUNITY: {
        return AmpEventSourceTypeCommunity.COMMUNITY;
      }
      case PlatformModel.ADMIN: {
        return AmpEventSourceTypeAdmin.ADMIN;
      }
    }
    return '';
  }


  /**
   * This method is responsible to check if the user that about to login, is an active zi\dozi user.
   *  this is for back compatibility - this cookie userd to represent a logged in user without considering the platform
   * **/
  isActiveUser() {
    return !!this.loginCookieService.getCookie(LoginConfig.ACTIVE_USER_COOKIE_NAME);
  }

  /**
   * This method is responsible to check if the user that about to login, is an active zi user.
   * **/
  isZiActiveUser() {
    return !!this.loginCookieService.getCookie(LoginConfig.ACTIVE_ZI_USER_COOKIE_NAME);
  }

  /**
   * This method is responsible to check if the user that about to login, is an active dozi user.
   * **/
  isDoziActiveUser() {
    return !!this.loginCookieService.getCookie(LoginConfig.ACTIVE_DOZI_USER_COOKIE_NAME);
  }

  /**
   * This method is responsible to check if the user that about to login, is an active recruiter user.
   * **/
  isRecruiterActiveUser() {
    return !!this.loginCookieService.getCookie(LoginConfig.ACTIVE_RECRUITER_USER_COOKIE_NAME);
  }

  /**
   * This method is responsible to check if the user that about to login, is an active recruiter user.
   * **/
  isMarketingCloudActiveUser() {
    return !!this.loginCookieService.getCookie(LoginConfig.ACTIVE_MARKETING_CLOUD_USER_COOKIE_NAME);
  }

  /**
   * This method is responsible to return the cookie name for logged in user by platform
   * **/
  getUserCookieName(platform) {
    switch (platform) {
      case PlatformModel.RECRUITER: return LoginConfig.ACTIVE_RECRUITER_USER_COOKIE_NAME;
      case PlatformModel.MARKETING_CLOUD: return LoginConfig.ACTIVE_MARKETING_CLOUD_USER_COOKIE_NAME;
      case PlatformModel.ZI: return LoginConfig.ACTIVE_ZI_USER_COOKIE_NAME;
      default: return LoginConfig.ACTIVE_DOZI_USER_COOKIE_NAME;
    }
  }

  /**
   * This method is responsible to check whether the user is an enterpise user or not
   * **/
  isUserEnterprise(zoomRoles = []) {
    return zoomRoles.includes('GROW_ENTERPRISE') || zoomRoles.includes('GROW_BETA') || zoomRoles.includes('ENTERPRISE');
  }

  /**
   * This method is responsible to remember active users.
   * Saving a cookie that indicates that the user is active.
   * Valid for 3 months.
   * **/
  rememberActiveUser(resp) {
    const platform = this.getCurrentPlatform();
    const isZICommunityUser = platform === PlatformModel.ZI && !this.isUserEnterprise(resp.zoom_role);
    //
    const dontRememberCases = [isZICommunityUser];
    // If it's one of don't remember cases:
    //    1. Ignore
    //    2. Remove existing cookie
    if (dontRememberCases.some(item => item)) {
      this.loginCookieService.deleteCookie(LoginConfig.ACTIVE_ZI_USER_COOKIE_NAME, '/', environment.cookies_domain);
      this.loginCookieService.deleteCookie(LoginConfig.ACTIVE_DOZI_USER_COOKIE_NAME, '/', environment.cookies_domain);
      this.loginCookieService.deleteCookie(LoginConfig.ACTIVE_RECRUITER_USER_COOKIE_NAME, '/', environment.cookies_domain);
      this.loginCookieService.deleteCookie(LoginConfig.ACTIVE_MARKETING_CLOUD_USER_COOKIE_NAME, '/', environment.cookies_domain);
      return;
    }
    const userCookieName = this.getUserCookieName(platform);
    this.loginCookieService.setCookie(userCookieName, resp.email, 90, '/', environment.cookies_domain);
  }

  /**
   * This method is responsible to handle users that needs to be toggled.
   * Get the toggle status from the state and redirect to toggle page
   * **/
  handleToggledUserAfterSuccessfulLogin(userState: any = {}) {
    const { toggleFrom, toggleTo } = userState;
    const redirectUrl = this.urlService.getLoginFinalRedirectUrl();
    const infoFromRedirectUrl = this.urlService.getRedirectUrlInfo(redirectUrl);
    //
    let toggleParams = {from: toggleFrom, to: toggleTo, flow: LoginConfig.TOGGLE_FLOWS.LOGIN};
    const additionalToggleParams = {toUrl: '', fromUrl: '', redirectQp: '', forceMovingToAppOnFailure: false};
    // Extension:
    //    1. toUrl - in case of success toggle flow, redirect to redirect query param
    //    2. In case of a failure - want to stay on login page with a notification of the error
    if (infoFromRedirectUrl.isRedirectedFromExtension) {
      additionalToggleParams.toUrl = redirectUrl;
    }
      // Not Extension:
      //    1. toUrl - in case of success toggle flow, redirect to redirect query param
    //    2. forceMovingToAppOnFailure - Force moving to the relevant app on failure
    else {
      additionalToggleParams.toUrl = redirectUrl;
      additionalToggleParams.forceMovingToAppOnFailure = true;
    }
    toggleParams = Object.assign({}, toggleParams, additionalToggleParams);
    //
    this._store.dispatch(new UpdateGeneralInfoState({isLoading: true}));
    return this.toggleService.toggleAndRedirect(toggleParams);
  }

  checkExistenceOfErrorQpAndHandle() {
    const { errorQp } = this.urlService.getInfoFromUrl();
    if (errorQp) {
      try {
        const decoded_error_query_param = atob(errorQp);
        const errorQueryParamObj = JSON.parse(decoded_error_query_param);
        console.log(errorQueryParamObj);
      } catch (err) {
        console.log(err);
      }
    }
  }

  isExtension() {
    return window.location !== window.parent.location;
  }

  isInsideAnIframe() {
    return window.location !== window.parent.location;
  }

  isLziSfna() {
    const isInsideAnIframe = this.isInsideAnIframe();
    const redirectQp = this.urlService.getInfoFromUrl().redirectQp;
    //
    return !!(isInsideAnIframe && !redirectQp);

  }

  /**
   * checking if the input is a number and retuning it, otherwise returning default number
   * **/
  validateAndGetNumber(number, defaultNumber: number = 500) {
    try {
      if (number) {
        return !isNaN(Number(number)) ? Number(number) : defaultNumber;
      } else {
        return defaultNumber;
      }
    } catch (e) {
      return defaultNumber;
    }
  }

  // checking if should show the new app launcher
  shouldShowNewAppLauncher(): boolean {
    return true;  // always show new app launcher
  }

  getReachOutEnterprise(zoomRoles) {
    return this.isUserEnterprise(zoomRoles) ? AmpEventSourceTypeZi.REACHOUT : AmpEventSourceTypeZi.CE_REACHOUT;
  }

  getZIEnterprise(zoomRoles) {
    return this.isUserEnterprise(zoomRoles) ? AmpEventSourceTypeZi.GO : AmpEventSourceTypeZi.CE;
  }

  setRedirectUrlCookie() {
    console.log(`get ssoredirecturl from url=${this._router.url}`);
    const queryParamMap = this._activatedRoute.snapshot.queryParamMap;
    const clientRedirectUrl = queryParamMap.get('redirect');
    let ssoredirecturl = this.urlService.isUrlAllowedForRedirect(clientRedirectUrl) ? clientRedirectUrl : '';
    ssoredirecturl = LoginService.handleSkilljarRedirectUrl(location.href, ssoredirecturl);
    console.log(`get ssoredirecturl=${ssoredirecturl} from redirect queryParam`);
    const redirectUrl = this.urlService.getLoginFinalRedirectUrl();
    const infoFromRedirectUrl = this.urlService.getRedirectUrlInfo(redirectUrl);
    console.log(infoFromRedirectUrl.isRedirectedFromSFNA);
    /* istanbul ignore next */
    if (ssoredirecturl) {
      // set encoded ssoredirecturl into cookie ssoredirecturl
      this.loginCookieService.setCookie('ssoredirecturl', encodeURIComponent(ssoredirecturl), new Date(new Date().getTime() +  3 * 60 * 1000), '/', environment.cookies_domain);
    } else {
      this.loginCookieService.deleteCookie('ssoredirecturl', '/', environment.cookies_domain);
      const APP_IFRAME_CONTAINER = _.get(document.getElementById("app_iframe_container"), 'contentWindow');
      if (APP_IFRAME_CONTAINER) {
        APP_IFRAME_CONTAINER.postMessage({ key: 'ssoredirecturl', value: encodeURIComponent(ssoredirecturl), target: "LOGIN_MESSAGE", action: "Set" }, environment.dozi_url_enterprise + LoginConfig.SESSION_STORAGE_POST_URL);
      }
    }
  }

  /**
   * checking if should show Engage Welcome Banner by:
   * 1. isLDForEngageWelcomeBannerIsON
   * we want to show Engage Welcome Banner if the relevant flag is turned on
   * **/
   shouldShowEngageWelcomeBanner(): boolean {
     //REFACTOR: remove this method
     return false;
  }

  // Service message commands
  emitNotyChange(change: any) {
    this.emitChangeSourceForNoty.next(change);
  }

  // show or hide lastpass icons for username and password inputs depending on the show flag
  toggleLoginFormLastPassIconDisplay(show: boolean) {
    const usernameLPIcon = document.getElementById('__lpform_okta-signin-username_icon') as HTMLImageElement;
    const pwLPIcon = document.getElementById('__lpform_okta-signin-password_icon') as HTMLImageElement;

    if (usernameLPIcon && pwLPIcon) {
      usernameLPIcon.style.display = show ? 'initial' : 'none';
      pwLPIcon.style.display = show ? 'initial' : 'none';
    }
  }

  // determine platform and return reachout url of it
  convertToReachoutUrl(url: string) {
    if (url.indexOf('recruiter') !== -1)
      return this.getReachoutUrlByAnura(PlatformModel.RECRUITER);
    return this.getReachoutUrlByAnura(PlatformModel.DOZI);
  }

  /* istanbul ignore next */
  // update redirect url if matching app and feature flag is on
  updateRedirectUrlOnFF(redirectUrl: string) {
    if (redirectUrl === environment.dozi_url_community && LoginConfig.IS_DOZI_LITE_ENABLED) {
          redirectUrl = environment.dozi_lite_url;
    }
    return redirectUrl;
  }

  // set reachoutsso cookie for SSO login if in RO
  handleSsoCookieForRO(isReachout: boolean) {
    if (isReachout) {
      const myDate = new Date();
      this.loginCookieService.setCookie('reachoutsso', 'true', myDate.setMinutes(myDate.getMinutes() + 3), '/', environment.cookies_domain);
      console.log(`Set reachoutsso=${this.loginCookieService.getCookie('reachoutsso')}`);
    }
  }

  // popup window from SSO login will send a redirect url, store to local storage if it not stored already
  listenForSsoPopupRedirectUrl() {
    /* istanbul ignore next */
    window.addEventListener('message', (e) => {
      // check sender for security
      if (e.origin !== window.origin) {
        console.warn('listenForSsoPopupRedirectUrl - message from', e.origin);
        return;
      }
      console.log('listenForSsoPopupRedirectUrl - message received', e.data);
      if (!localStorage.getItem('popupredirecturl') && e.data.popupredirecturl) {
        localStorage.setItem('popupredirecturl', e.data.popupredirecturl);
      }
      if (!localStorage.getItem('roSsoPopupRedirectUrl') && e.data.roSsoPopupRedirectUrl) {
        localStorage.setItem('roSsoPopupRedirectUrl', e.data.roSsoPopupRedirectUrl);
      }
    });
  }

  // determine if user will have app launcher recommending Admin Portal
  checkForAppLauncherRecAdmin(): void {
    //REFACTOR: remove method
    this.isAppLauncherRecAdmin = LoginConfig.SHOULD_RECOMMEND_ADMIN;
  }

  getIsAppLauncherRecAdmin(): boolean {
    //REFACTOR: remove method
    return this.isAppLauncherRecAdmin;
  }

  checkForMaintenanceBannerFlag(): boolean {
    return LoginConfig.SHOW_MAINTENANCE_BANNER;
  }

  /** Wait for Amplitude to be ready */
  amplitudeReadyCheck(): Observable<[boolean, boolean]> {
    return forkJoin([
      this.analyticsService.amplitudeLoaded$,
      this.analyticsService.zoominfoAnalyticLoaded$,
    ]);
  }

  // add RelayState param to the redirectUrl
  getMobileSsoRedirectUrl(redirectUrl: string): string {
    const url = new URL(redirectUrl);
    url.searchParams.append('RelayState', `${environment.login_backend_domain}/sso/oidc/mob/authorize`);
    return url.toString();
  }

  /** Filter for platforms that are available in the app launcher. */
  static getRealPlatformNames(platformNames: string[]): string[] {
    return platformNames.filter(name => name !== PlatformModel.AAT);
  }

  /** Check if the user only has AAT license. */
  static hasOnlyAAT(ziPlatforms: string[]): boolean {
    return ziPlatforms.length === 1 && ziPlatforms[0].toLowerCase() === PlatformModel.AAT;
  }
}
