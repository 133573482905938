import * as _ from 'lodash-es';

import {CookieService} from "ngx-cookie-service";
import {Injectable} from "@angular/core";
import {UrlService} from "./url.service";
import {environment} from "../../../environments/environment";
import { LoginConfig } from '../../login/login.config';

@Injectable()
export class LoginCookieService {

  constructor(private _cookiesService: CookieService, private urlService: UrlService) { }

  public getCookie(name: string) {
    return this._cookiesService.get(name);
  }

  public setCookie(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secureCookies?: boolean, sameSite?: 'None' | 'Lax' | 'Strict') {
    const securityAttrs = this.determineCookieSecurityAttributes();
    if (_.isNil(secureCookies)) {
      secureCookies = securityAttrs.secureCookies;
    }
    if (_.isNil(sameSite)) {
      sameSite = securityAttrs.sameSite;
    }
    this._cookiesService.set(name, value, expires, path, domain, secureCookies, sameSite);
  }

  public deleteCookie(name: string, path?: string, domain?: string, secureCookies?: boolean, sameSite?: 'None' | 'Lax' | 'Strict') {
    //Due to the chrome 80 changes, when deleting a cookie, need to use set because cookie service doesn't delete
    //secure cookies.
    const securityAttrs = this.determineCookieSecurityAttributes();
    if (_.isNil(secureCookies)) {
      secureCookies = securityAttrs.secureCookies;
    }
    if (_.isNil(sameSite)) {
      sameSite = sameSite || securityAttrs.sameSite;
    }
    this._cookiesService.set(name, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), path, domain, secureCookies, sameSite);
  }

  /** Delete all cookies with given prefix for the name. */
  public deleteCookiesByPrefix(namePrefix: string, path?: string, domain?: string): void {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const trimmedCookie = cookie.trim();
      const cookieName = trimmedCookie.split("=")[0];

      if (cookieName.indexOf(namePrefix) === 0) {
        this.deleteCookie(cookieName, path, domain);
      }
    }
  }

  /**
   *
   * This method is responsible to determine the proper security cookie attributes
   *
   * @Logic_is_as_follows:
   *
   *    1. Secure:
   *        - Default: True
   *        - Want to force in-secure cookies (logic in figureIfWantToForceInsecureCookies method) - "secure: false"
   *        - Don't want to force - Set secure if Production OR redirected from extension.
   *
   *    2. SameSite:
   *        - "SameSite: None" cookies requires "secure: true"
   *        - "SameSite: None" for cross-domain cookies communication with apps running under a different domain (such as with extensions / iFrames)
   *        - Once cookie is "secure: true" - cookie is going to have "SameSite: None"
   *        - Once cookie is "secure: false" - cookie is going to have "SameSite: Lax"
   */
  private determineCookieSecurityAttributes(): {sameSite: 'None' | 'Lax' | 'Strict', secureCookies: boolean} {
    const redirectUrl = this.urlService.getLoginFinalRedirectUrl();
    const { isReachout } = this.urlService.getInfoFromUrl();
    const { isRedirectedFromExtension } = this.urlService.getRedirectUrlInfo(redirectUrl, isReachout);
    const wantToForceInsecureCookies = this.figureIfWantToForceInsecureCookies();
    let secureCookies = true;
    //
    if (wantToForceInsecureCookies) {
      secureCookies = false;
    }
    else {
      secureCookies = environment.production || environment.name === 'staging' || isRedirectedFromExtension;
    }
    //
    const sameSite = secureCookies ? 'None' : 'Lax';
    //
    return {
      secureCookies,
      sameSite
    };
  }

  /**
   * This method is responsible to figure out if we want to force insecure cookies or not.
   *
   * @Logic_is_as_follows:
   *
   *    1. Running Login App locally
   *    2. Not Production && Redirected from a local app
   *
   * **/
  private figureIfWantToForceInsecureCookies() {
    const redirectUrl = this.urlService.getLoginFinalRedirectUrl();
    const { isRedirectedFromDevApp, shouldUseSecuredCookie } = this.urlService.getRedirectUrlInfo(redirectUrl);
    const currentDomain = window.location.origin;
    //
    const isDevMode = currentDomain.includes('dev.zoominfo.com');
    const notProdModeAndRedirectedFromLocalApp = !environment.production && isRedirectedFromDevApp;
    //
    return (isDevMode || notProdModeAndRedirectedFromLocalApp) && !shouldUseSecuredCookie;
  }

  handleCopilotCookie(isCopilotUser: boolean): void {
    if (isCopilotUser) {
      this.setCookie(LoginConfig.COPILOT_USER_COOKIE_NAME, 'true', 30, '/', environment.cookies_domain);  // store copilot flag
    } else {
      this.deleteCookie(LoginConfig.COPILOT_USER_COOKIE_NAME, '/', environment.cookies_domain);  // remove copilot flag
    }
  }

  hasAccessTokenCookie(): boolean {
    return !!this.getCookie('ziaccesstoken');
  }
}
